import { useEffect } from "react";
import { DataStore } from "../../../Store";
import { OurMission, OurVision, OurTeam } from "../Elements/AboutElements";
export default function AboutContent() {
  const metatags = DataStore((state) => state.metatags);
  useEffect(() => {
    metatags
      .filter((tag) => tag.tag_page == "about")
      .forEach((row) => {
        document.title = "Minassa Solutions - " + row.tag_title;
        document.getElementsByTagName("meta")["description"].content =
          row.tag_description;
        document.getElementsByTagName("meta")["keywords"].content =
          row.tag_keyword;
      });
  }, []);

  return (
    <>
      <OurMission />
      <OurVision />
      <OurTeam />
    </>
  );
}
