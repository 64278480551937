import { Box, Container, Typography, Grid } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Img } from "react-image";
import { CircleLoader } from "../../../Common/Loaders";
import Logo from "../../../../Assets/IMG/logoFooter.png";
import { NavLink, Link } from "react-router-dom";
import { Fade, Zoom, Slide, JackInTheBox } from "react-awesome-reveal";
import { DataStore } from "../../../../Store";
export default function Footer() {
  const contactdetails = DataStore((state) => state.contactdetails);
  const footerser = DataStore((state) => state.footerser);
  return (
    <Container
      maxWidth="auto"
      sx={{ p: 2, fontSize: "small", color: "#fff" }}
      className="BlackBox"
    >
      <Container maxWidth="lg">
        <Grid container spacing={3} sx={{ my: 2 }}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Slide delay={100} direction="left" triggerOnce>
              <Img
                src={Logo}
                loader={<CircleLoader />}
                className="footerLogo"
              />
            </Slide>
            <Zoom delay={200} triggerOnce>
              <Typography
                variant="body2"
                sx={{ margin: "2rem 1rem 1rem 1rem" }}
              >
                Follow us on :
              </Typography>
            </Zoom>
            {contactdetails.map((row) => (
              <Box key={row.cont_id} className="social">
                <Fade delay={300} duration={500} cascade triggerOnce>
                  {row.facebook && (
                    <Link
                      to={row.facebook}
                      target="_blanck"
                      className="footerlink"
                    >
                      <FacebookIcon />
                    </Link>
                  )}
                  {row.instagram && (
                    <Link
                      to={row.instagram}
                      target="_blanck"
                      className="footerlink"
                    >
                      <InstagramIcon />
                    </Link>
                  )}
                  {row.linked_in && (
                    <Link
                      to={row.linked_in}
                      target="_blanck"
                      className="footerlink"
                    >
                      <LinkedInIcon />
                    </Link>
                  )}
                  {row.xtwitter && (
                    <Link
                      to={row.xtwitter}
                      target="_blanck"
                      className="footerlink"
                    >
                      <XIcon />
                    </Link>
                  )}
                  {row.youtube && (
                    <Link
                      to={row.youtube}
                      target="_blanck"
                      className="footerlink"
                    >
                      <YouTubeIcon />
                    </Link>
                  )}
                </Fade>
              </Box>
            ))}
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Zoom delay={200} triggerOnce>
              <Typography sx={{ my: 3 }}>Our Company</Typography>
            </Zoom>
            <Box>
              <Fade delay={300} duration={500} cascade triggerOnce>
                <NavLink
                  className="footerlink footerlinkextra"
                  to="/"
                  title="Home Page"
                >
                  Home
                </NavLink>
                <NavLink
                  className="footerlink footerlinkextra"
                  to="/about"
                  title="About Us"
                >
                  About
                </NavLink>
                <NavLink
                  className="footerlink footerlinkextra"
                  to="/career"
                  title="Careers"
                >
                  Careers
                </NavLink>
                <NavLink
                  className="footerlink footerlinkextra"
                  to="/support"
                  title="Support"
                >
                  Support
                </NavLink>
                <NavLink
                  className="footerlink footerlinkextra"
                  to="/contact"
                  title="Contact Us"
                >
                  Contact
                </NavLink>
              </Fade>
            </Box>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Zoom delay={200} triggerOnce>
              <Typography sx={{ my: 3 }}>Our Services</Typography>
            </Zoom>

            <Box>
              <Fade delay={300} duration={500} cascade triggerOnce>
                {footerser.map((row) => (
                  <NavLink
                    key={row.service_id}
                    className="footerlink footerlinkextra"
                    to={"/service/" + row.service_min_url}
                    title={row.service_name}
                  >
                    {row.service_name}
                  </NavLink>
                ))}
              </Fade>
            </Box>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <JackInTheBox delay={100} triggerOnce>
              <Typography sx={{ my: 3 }}>Hire Us</Typography>
            </JackInTheBox>
            <Box>
              <Zoom delay={200} triggerOnce>
                <Typography sx={{ fontSize: "small", pb: 2 }}>
                  Hire our team for expert web, mobile and desktop application
                  development.
                </Typography>
              </Zoom>
              <Fade delay={300} triggerOnce>
                <NavLink
                  className="getaqoute"
                  to="/hireus"
                  title="Request A Qoute"
                >
                  Request A Qoute
                </NavLink>
              </Fade>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}
