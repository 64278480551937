import { Container, Typography, Box } from "@mui/material";
import { Fade } from "react-awesome-reveal";
import { DataStore } from "../../../../Store";
import HTMLReactParser from "html-react-parser";
export default function HireUsHeader() {
  const pageheaders = DataStore((state) => state.pageheaders);
  return (
    <Container maxWidth="auto" className="pagerHeader" sx={{ p: 4 }}>
      <Box
        sx={{
          p: 3,
          textAlign: "center",
          color: "white",
        }}
      >
        {pageheaders
          .filter((cont) => cont.page_name == "hireus")
          .map((row) => (
            <Fade
              key={row.header_id}
              delay={100}
              duration={500}
              cascade
              triggerOnce
            >
              <Typography variant="h6">{row.page_title}</Typography>
              <Box sx={{ mt: 3 }}>{HTMLReactParser(row.page_description)}</Box>
            </Fade>
          ))}
      </Box>
    </Container>
  );
}
