import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Snackbar,
} from "@mui/material";
import { Zoom } from "react-awesome-reveal";
import { useState } from "react";
import { APIURL } from "../../../../Context/configs";
import axios from "axios";
import { FacebookLoader } from "../../../Common/Loaders";
export default function HireUsForm() {
  const [RPTFILE, SetRPTFILE] = useState("");
  const [Uploading, SetUploading] = useState(false);
  const [startedUpload, SetstartedUpload] = useState(false);
  const [SnackBarType, SetSnackBarType] = useState("");
  const [SnackBarMessage, SetSnackBarMessage] = useState("");
  const [SnackBarShow, SetSnackBarShow] = useState(false);
  const startUpload = (event) => {
    SetUploading(false);
    SetstartedUpload(true);
    const formData = new FormData();
    formData.append("cv", event.target.files[0]);
    axios
      .post(APIURL + "site/uploadProject.php", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(function (response) {
        response.data.forEach((item) => {
          if (item.id === 1) {
            SetRPTFILE(item.message);
            setTimeout(() => {
              SetstartedUpload(false);
              SetUploading(true);
            }, 3000);
          } else {
            setTimeout(() => {
              SetstartedUpload(false);
              SetUploading(true);
            }, 3000);
          }
        });
      });
  };
  const sendRequest = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    fetch(APIURL + "site/requestqoutes.php", {
      method: "post",
      body: JSON.stringify({
        comn: data.get("fullname"),
        come: data.get("email"),
        projn: data.get("subject"),
        projd: data.get("message"),
        projf: RPTFILE,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackBarMessage(item.content);
          SetSnackBarShow(true);
          if (item.id === 1) {
            SetSnackBarType("success");
          } else {
            SetSnackBarType("error");
          }
        });
        event.target.reset();
      });
  };
  return (
    <Container maxWidth="lg" sx={{ p: 4, mt: 5 }}>
      <Box>
        <Typography variant="h5" className="TextPurple">
          Hire Our Team
        </Typography>
        <Box component="form" onSubmit={sendRequest} sx={{ my: 3 }}>
          <Zoom cascade delay={100} duration={600} triggerOnce>
            <Box>
              <TextField
                id="fullname"
                name="fullname"
                label="Your Name"
                variant="standard"
                color="secondary"
                fullWidth
                size="small"
                InputLabelProps={{
                  style: { color: "#6a1b9a" },
                }}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="email"
                name="email"
                label="Your Email"
                variant="standard"
                color="secondary"
                fullWidth
                type="email"
                size="small"
                InputLabelProps={{
                  style: { color: "#6a1b9a" },
                }}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="subject"
                name="subject"
                label="Project Name"
                variant="standard"
                color="secondary"
                fullWidth
                size="small"
                type="subject"
                InputLabelProps={{
                  style: { color: "#6a1b9a" },
                }}
              />
            </Box>
            <Box sx={{ my: 2 }}>
              <TextField
                id="message"
                name="message"
                label="Project Details"
                variant="standard"
                color="secondary"
                fullWidth
                rows={4}
                multiline
                size="small"
              />
            </Box>
            <Box sx={{ my: 2, pt: 5 }}>
              <Typography className="TextPurple">
                Add Any Attachment files for you project{" "}
                <Typography
                  sx={{ color: "red", fontSize: "x-small" }}
                  variant="body"
                  className="small"
                >
                  (Only Zip, Rar files accepted)
                </Typography>
              </Typography>
            </Box>
            <Box sx={{ my: 2 }}>
              <TextField
                id="file"
                name="file"
                variant="standard"
                color="secondary"
                fullWidth
                type="file"
                size="small"
                onChange={startUpload}
                inputProps={{ accept: ".zip,.rar" }}
              />
            </Box>
          </Zoom>
          {startedUpload && (
            <Box sx={{ textAlign: "center", my: 2 }}>
              <FacebookLoader />
            </Box>
          )}
          {Uploading && (
            <Box sx={{ textAlign: "center", my: 2 }}>
              File uploaded successfully
            </Box>
          )}
          <Box sx={{ mt: 5 }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Request Qoute
            </Button>
          </Box>
        </Box>
      </Box>
      <Snackbar
        autoHideDuration={3000}
        open={SnackBarShow}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert variant="filled" severity={SnackBarType} sx={{ width: "100%" }}>
          {SnackBarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
