import { Container, Box, Typography } from "@mui/material";
import { Zoom, Fade } from "react-awesome-reveal";
import { DataStore } from "../../../../Store";
export default function OurMission() {
  const ourmission = DataStore((state) => state.ourmission);
  return (
    <Container maxWidth="lg" sx={{ p: 4, mt: 5 }}>
      {ourmission.map((row) => (
        <Box
          key={row.mission_id}
          sx={{ display: "flex", textAlign: "center", flexDirection: "column" }}
        >
          <Fade delay={100} duration={300} triggerOnce>
            <Typography variant="h5" className="TextPurple">
              {row.mission_title}
            </Typography>
          </Fade>
          <Zoom delay={500} duration={300} triggerOnce>
            <Typography sx={{ mt: 3 }}>{row.mission_text}</Typography>
          </Zoom>
        </Box>
      ))}
    </Container>
  );
}
