import { Container, Box, Typography, Grid } from "@mui/material";
import { Zoom, Fade } from "react-awesome-reveal";
import { Img } from "react-image";
import { DualRingLoader } from "../../../Common/Loaders";
import { STORAGEAPI } from "../../../../Context/configs";
import { DataStore } from "../../../../Store";
export default function OurTeam() {
  const ourteam = DataStore((state) => state.ourteam);
  return (
    <Container maxWidth="lg" sx={{ p: 4, mt: 5 }}>
      <Box
        sx={{ display: "flex", textAlign: "center", flexDirection: "column" }}
      >
        <Fade delay={100} triggerOnce>
          <Typography variant="h5" className="TextPurple">
            Our Team
          </Typography>
        </Fade>
        <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
          <Zoom cascade duration={800} triggerOnce>
            {ourteam.map((row) => (
              <Box key={row.team_id} className="rad">
                <Img
                  src={STORAGEAPI + row.team_picture}
                  alt={row.team_name}
                  title={row.team_name}
                  className="ourTeamPic"
                  loader={<DualRingLoader />}
                />
                <Typography variant="h6" className="TextPurple" sx={{ my: 1 }}>
                  {row.team_name}
                </Typography>
                <Typography sx={{ my: 1, fontSize: "small" }}>
                  {row.team_position}
                </Typography>
              </Box>
            ))}
          </Zoom>
        </Box>
      </Box>
    </Container>
  );
}
