import {
  AppBar,
  Container,
  Toolbar,
  Grid,
  IconButton,
  Box,
  Divider,
} from "@mui/material";
import { useState } from "react";
import { Img } from "react-image";
import { CircleLoader } from "../Loaders";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import LOGO from "../../../Assets/IMG/logo.png";
import { NavLink } from "react-router-dom";
import MenuSharpIcon from "@mui/icons-material/MenuSharp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
const drawerWidth = "100%";
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
  [theme.breakpoints.up("sm")]: {
    width: 0,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));
const DrawerBody = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
export default function MobileNavigations() {
  const [NavOpen, SetNavOpen] = useState(false);
  const handleDrawer = () => {
    SetNavOpen(!NavOpen);
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <AppBar position="static" color="default">
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Grid container>
              <Grid item sx={{ my: 1 }}>
                <Img
                  className="HeaderIMG"
                  src={LOGO}
                  loader={<CircleLoader />}
                />
              </Grid>
              <Grid
                item
                sx={{
                  textAlign: "end",
                  ml: "auto",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <IconButton
                  className="DrawerBut"
                  onClick={handleDrawer}
                  sx={{ mr: 1 }}
                >
                  {NavOpen ? <CloseSharpIcon /> : <MenuSharpIcon />}
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <DrawerBody variant="permanent" open={NavOpen}>
        <DrawerHeader>
          <IconButton
            className="DrawerBut"
            onClick={handleDrawer}
            sx={{ mr: 1 }}
          >
            {NavOpen ? <CloseSharpIcon /> : <MenuSharpIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box>
          <NavLink
            className="navlink moblink"
            to="/"
            title="Home Page"
            onClick={handleDrawer}
          >
            Home
          </NavLink>
          <NavLink
            className="navlink moblink"
            to="/about"
            title="About Us"
            onClick={handleDrawer}
          >
            About
          </NavLink>
          <NavLink
            className="navlink moblink"
            to="/services"
            title="Our Services"
            onClick={handleDrawer}
          >
            Services
          </NavLink>
          <NavLink
            className="navlink moblink"
            to="/hireus"
            title="Hire Us"
            onClick={handleDrawer}
          >
            Hire Us
          </NavLink>
          <NavLink
            className="navlink moblink"
            to="/career"
            title="Careers"
            onClick={handleDrawer}
          >
            Careers
          </NavLink>
          <NavLink
            className="navlink moblink"
            to="/support"
            title="Support"
            onClick={handleDrawer}
          >
            Support
          </NavLink>
          <NavLink
            className="navlink moblink"
            to="/contact"
            title="Contact Us"
            onClick={handleDrawer}
          >
            Contact
          </NavLink>
        </Box>
      </DrawerBody>
    </Box>
  );
}
