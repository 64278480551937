import { Container, Typography, Box } from "@mui/material";
import { Fade } from "react-awesome-reveal";
import { DataStore } from "../../../../Store";
import HTMLReactParser from "html-react-parser";
import { useParams } from "react-router-dom";
export default function JobHeader() {
  const pageheaders = DataStore((state) => state.pageheaders);
  const { jid } = useParams();
  const toview = pageheaders.filter(
    (cont) => cont.page_name == "job" && cont.page_sub_id === jid
  );
  return (
    <Container maxWidth="auto" className="pagerHeader" sx={{ p: 4 }}>
      <Box
        sx={{
          p: 3,
          textAlign: "center",
          color: "white",
        }}
      >
        {toview === 0 ? (
          <>
            {pageheaders
              .filter((cont) => cont.page_name == "404")
              .map((row) => (
                <Fade
                  key={row.header_id}
                  delay={100}
                  duration={500}
                  cascade
                  triggerOnce
                >
                  <Typography variant="h6">{row.page_title}</Typography>
                  <Box sx={{ mt: 3 }}>
                    {HTMLReactParser(row.page_description)}
                  </Box>
                </Fade>
              ))}
          </>
        ) : (
          <>
            {pageheaders
              .filter(
                (cont) => cont.page_name == "job" && cont.page_sub_id === jid
              )
              .map((row) => (
                <Fade
                  key={row.header_id}
                  delay={100}
                  duration={500}
                  cascade
                  triggerOnce
                >
                  <Typography variant="h6">{row.page_title}</Typography>
                  <Box sx={{ mt: 3 }}>
                    {HTMLReactParser(row.page_description)}
                  </Box>
                </Fade>
              ))}
          </>
        )}
      </Box>
    </Container>
  );
}
