import { Container, Box, Typography, Paper, Grid } from "@mui/material";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import TvIcon from "@mui/icons-material/Tv";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { Bounce } from "react-awesome-reveal";
import { DataStore } from "../../../../Store";
export default function FeaturesList() {
  const homepagefeatures = DataStore((state) => state.homepagefeatures);
  return (
    <Container maxWidth="lg" sx={{ p: 4, mt: 5 }}>
      <Grid container spacing={3} sx={{ textAlign: "center" }}>
        {homepagefeatures
          .filter((cont) => cont.feature_sec == "creativesol")
          .map((row) => (
            <Grid key={row.feature_id} item lg={4} md={4}>
              <Bounce delay={100} duration={300} triggerOnce>
                <Paper
                  elevation={3}
                  sx={{ p: 3, mx: 2 }}
                  className="paperPurple"
                >
                  <Box>
                    <TipsAndUpdatesIcon fontSize="large" />
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <Typography variant="h6">{row.feature_title}</Typography>
                  </Box>
                  <Box sx={{ mt: 3, px: 5 }}>
                    <Typography variant="body">
                      {row.feature_descrition}
                    </Typography>
                  </Box>
                </Paper>
              </Bounce>
            </Grid>
          ))}
        {homepagefeatures
          .filter((cont) => cont.feature_sec == "businessser")
          .map((row) => (
            <Grid key={row.feature_id} item lg={4} md={4}>
              <Bounce delay={400} duration={300} triggerOnce>
                <Paper
                  elevation={3}
                  sx={{ p: 3, mx: 2 }}
                  className="paperPurple"
                >
                  <Box>
                    <BusinessCenterIcon fontSize="large" />
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <Typography variant="h6">{row.feature_title}</Typography>
                  </Box>
                  <Box sx={{ mt: 3, px: 5 }}>
                    <Typography variant="body">
                      {row.feature_descrition}
                    </Typography>
                  </Box>
                </Paper>
              </Bounce>
            </Grid>
          ))}
        {homepagefeatures
          .filter((cont) => cont.feature_sec == "customeapps")
          .map((row) => (
            <Grid key={row.feature_id} item lg={4} md={4}>
              <Bounce delay={700} duration={300} triggerOnce>
                <Paper
                  elevation={3}
                  sx={{ p: 3, mx: 2 }}
                  className="paperPurple"
                >
                  <Box>
                    <TvIcon fontSize="large" />
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <Typography variant="h6">{row.feature_title}</Typography>
                  </Box>
                  <Box sx={{ mt: 3, px: 5 }}>
                    <Typography variant="body">
                      {row.feature_descrition}
                    </Typography>
                  </Box>
                </Paper>
              </Bounce>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
}
