import { Container, Box, Typography, Grid, Paper } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Zoom, Fade, JackInTheBox } from "react-awesome-reveal";
import { CircleLoader } from "../../../Common/Loaders";
import { Img } from "react-image";
import { DataStore } from "../../../../Store";
import { STORAGEAPI } from "../../../../Context/configs";
export default function ServicesLister() {
  const ourservices = DataStore((state) => state.ourservices);
  return (
    <Container maxWidth="lg" sx={{ p: 4, mt: 5 }}>
      <Zoom cascade delay={100} duration={600} triggerOnce>
        {ourservices.map((row) => (
          <Box key={row.service_id} sx={{ my: 2 }}>
            <Paper elevation={3} sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item lg={3} md={4}>
                  <JackInTheBox delay={150} triggerOnce>
                    <Img
                      className="serIMG"
                      src={STORAGEAPI + row.service_logo}
                      loader={<CircleLoader />}
                    />
                  </JackInTheBox>
                </Grid>
                <Grid item lg={9} md={8}>
                  <Fade cascade delay={200} duration={300} triggerOnce>
                    <Box sx={{ my: 2 }}>
                      <Typography className="TextPurple" variant="h5">
                        {row.service_name}
                      </Typography>
                    </Box>
                    <Box sx={{ my: 2 }}>
                      <Typography variant="body">
                        {row.service_description}
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 6, textAlign: "end" }}>
                      <NavLink
                        className="navlink"
                        to={"/service/" + row.service_min_url}
                        title="Home Page"
                      >
                        View Service
                      </NavLink>
                    </Box>
                  </Fade>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        ))}
      </Zoom>
    </Container>
  );
}
