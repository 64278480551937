import { Container, Box, Typography } from "@mui/material";
import { Zoom } from "react-awesome-reveal";
import { DataStore } from "../../../../Store";
import { useParams } from "react-router-dom";
import HTMLReactParser from "html-react-parser";
export default function JobInfo() {
  const careers = DataStore((state) => state.careers);
  const { jid } = useParams();
  const toview = careers.filter((cont) => cont.job_link === jid);
  return (
    <Container maxWidth="lg" sx={{ p: 4, mt: 5 }}>
      {toview.map((row) => (
        <Zoom key={row.job_id} cascade duration={600} delay={100} triggerOnce>
          <Box>
            <Typography variant="h4" className="TextPurple">
              {row.job_title}
            </Typography>
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Job Describtion
            </Typography>
            <Box sx={{ p: 3 }}>{HTMLReactParser(row.job_desc)}</Box>
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Job Duties
            </Typography>
            <Box sx={{ p: 3 }}>{HTMLReactParser(row.job_duties)}</Box>
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Job Requirement
            </Typography>
            <Box sx={{ p: 3 }}>{HTMLReactParser(row.job_requirement)}</Box>
          </Box>
        </Zoom>
      ))}
    </Container>
  );
}
