import { Container, Box, Typography } from "@mui/material";
import { Zoom, Fade } from "react-awesome-reveal";
import { DataStore } from "../../../../Store";
export default function OurVision() {
  const ourvision = DataStore((state) => state.ourvision);
  return (
    <Container maxWidth="auto" sx={{ p: 4, mt: 5 }} className="BlackBox">
      <Container
        maxWidth="lg"
        sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}
      >
        {ourvision.map((row) => (
          <Box
            key={row.vision_id}
            sx={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              color: "white",
            }}
          >
            <Fade delay={100} duration={300} triggerOnce>
              <Typography variant="h5">{row.vision_title}</Typography>
            </Fade>
            <Zoom delay={500} duration={300} triggerOnce>
              <Typography sx={{ mt: 3 }}>{row.vision_text}</Typography>
            </Zoom>
          </Box>
        ))}
      </Container>
    </Container>
  );
}
