import { Container, Box, Typography, Paper } from "@mui/material";
import { Zoom, Fade } from "react-awesome-reveal";
import { NavLink } from "react-router-dom";
export default function CareerLister(props) {
  return (
    <Container maxWidth="lg" sx={{ p: 4, mt: 5 }}>
      <Fade delay={100} triggerOnce>
        <Typography variant="h5" className="TextPurple" sx={{ px: 2 }}>
          Available Jobs
        </Typography>
      </Fade>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Zoom cascade duration={400} delay={200} triggerOnce>
          {props.data.map((row) => (
            <Paper key={row.job_id} elevation={3} className="jobContainer">
              <Typography variant="h6">{row.job_title}</Typography>
              <Typography sx={{ my: 2 }}>{row.job_short_desc}</Typography>
              <Typography sx={{ my: 2 }}>Ends : {row.closingDate}</Typography>
              <Box sx={{ textAlign: "end", my: 2, width: "100%" }}>
                <NavLink
                  className="JobLInk"
                  to={"/job/" + row.job_link}
                  title="Apply For Job"
                >
                  Apply For Job
                </NavLink>
              </Box>
            </Paper>
          ))}
        </Zoom>
      </Box>
    </Container>
  );
}
