import { Container, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { GridLoader } from "./Components/Common/Loaders/";
import { MobileView, DesktopView } from "./Views";
import { APIURL } from "./Context/configs";
import { DataStore } from "./Store";

export default function App() {
  const [isLoading, SetisLoading] = useState(true);
  const setmetatags = DataStore((state) => state.setmetatags);
  const setcareers = DataStore((state) => state.setcareers);
  const setpageheaders = DataStore((state) => state.setpageheaders);
  const sethomepagefeatures = DataStore((state) => state.sethomepagefeatures);
  const sethomepageservices = DataStore((state) => state.sethomepageservices);
  const setourclients = DataStore((state) => state.setourclients);
  const setourmission = DataStore((state) => state.setourmission);
  const setourvision = DataStore((state) => state.setourvision);
  const setourteam = DataStore((state) => state.setourteam);
  const setourservices = DataStore((state) => state.setourservices);
  const setcontactdetails = DataStore((state) => state.setcontactdetails);
  const setfooterser = DataStore((state) => state.setfooterser);
  useEffect(() => {
    fetch(APIURL + "site/metatags.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setmetatags(result);
      });
    fetch(APIURL + "site/pageheaders.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setpageheaders(result);
      });
    fetch(APIURL + "site/homepagefeatures.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        sethomepagefeatures(result);
      });
    fetch(APIURL + "site/homepageservices.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        sethomepageservices(result);
      });
    fetch(APIURL + "site/ourclients.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setourclients(result);
      });
    fetch(APIURL + "site/ourmission.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setourmission(result);
      });
    fetch(APIURL + "site/ourvision.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setourvision(result);
      });
    fetch(APIURL + "site/ourteam.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setourteam(result);
      });
    fetch(APIURL + "site/ourservices.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setourservices(result);
      });
    fetch(APIURL + "site/careers.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setcareers(result);
      });
    fetch(APIURL + "site/footerservices.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setfooterser(result);
      });
    fetch(APIURL + "site/contactdetails.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setcontactdetails(result);
        SetisLoading(false);
      });
  }, []);

  return (
    <Container maxWidth="auto" disableGutters>
      {isLoading ? (
        <Box className="mainLoadingContainer">
          <GridLoader />
          <Typography variant="body" sx={{ mt: 2 }}>
            Loading ...
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "block", lg: "block" },
            }}
          >
            <DesktopView />
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "block", md: "none", lg: "none" },
            }}
          >
            <MobileView />
          </Box>
        </>
      )}
    </Container>
  );
}
