import { Routes, Route } from "react-router-dom";
import { Container } from "@mui/material";
import {
  HomeHeader,
  AboutHeader,
  ServicesHeader,
  HireUsHeader,
  CareersHeader,
  SupportHeader,
  ContactHeader,
  NotFoundHeader,
  ServiceDetailsHeader,
  JobHeader,
} from "../Elements/Headers";
export default function HeadersContainer() {
  return (
    <Container maxWidth="auto" disableGutters>
      <Routes>
        <Route path="/" element={<HomeHeader />} />
        <Route path="/home" element={<HomeHeader />} />
        <Route path="/about" element={<AboutHeader />} />
        <Route path="/services" element={<ServicesHeader />} />
        <Route path="/service/:sid" element={<ServiceDetailsHeader />} />
        <Route path="/hireus" element={<HireUsHeader />} />
        <Route path="/career" element={<CareersHeader />} />
        <Route path="/job/:jid" element={<JobHeader />} />
        <Route path="/support" element={<SupportHeader />} />
        <Route path="/contact" element={<ContactHeader />} />
        <Route path="/*" element={<NotFoundHeader />} />
      </Routes>
    </Container>
  );
}
