import { Container, Box, Typography, Chip } from "@mui/material";
import { Zoom } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import { DataStore } from "../../../../Store";
import { useParams } from "react-router-dom";
import { Fragment } from "react";
import HTMLReactParser from "html-react-parser";
export default function ServiceDetails() {
  const ourservices = DataStore((state) => state.ourservices);
  const { sid } = useParams();
  return (
    <Container maxWidth="lg" sx={{ p: 4, mt: 5 }}>
      {ourservices
        .filter((ser) => ser.service_min_url === sid)
        .map((row) => (
          <Fragment key={row.service_id}>
            <Box sx={{ my: 2 }}>
              <Zoom cascade duration={600} delay={100} triggerOnce>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Features
                </Typography>
                <Typography sx={{ p: 3 }}>
                  {HTMLReactParser(row.service_features)}
                </Typography>
              </Zoom>
            </Box>
            <Box sx={{ my: 2, textAlign: "end" }}>
              <Zoom cascade duration={600} delay={100} triggerOnce>
                {row.service_active === 1 ? (
                  <Link
                    target="_blank"
                    to={row.service_main_url}
                    className="navlink"
                  >
                    View Service
                  </Link>
                ) : (
                  <Chip label="Coming Soon!" color="secondary" />
                )}
              </Zoom>
            </Box>
          </Fragment>
        ))}
    </Container>
  );
}
