import {
  ServiceName,
  ServiceDetails,
} from "../Elements/ServicePreviewElements";
import { useEffect } from "react";
import { DataStore } from "../../../Store";
import { useParams } from "react-router-dom";
export default function ServicePreview() {
  const metatags = DataStore((state) => state.metatags);
  const { sid } = useParams();
  const toview = metatags.filter(
    (cont) => cont.tag_page == "service" && cont.tag_sub_id === sid
  ).length;
  useEffect(() => {
    if (toview === 0) {
      metatags
        .filter((tag) => tag.tag_page == "404")
        .forEach((row) => {
          document.title = "Minassa Solutions - " + row.tag_title;
          document.getElementsByTagName("meta")["description"].content =
            row.tag_description;
          document.getElementsByTagName("meta")["keywords"].content =
            row.tag_keyword;
        });
    } else {
      metatags
        .filter((tag) => tag.tag_page == "service" && tag.tag_sub_id === sid)
        .forEach((row) => {
          document.title = "Minassa Solutions - " + row.tag_title;
          document.getElementsByTagName("meta")["description"].content =
            row.tag_description;
          document.getElementsByTagName("meta")["keywords"].content =
            row.tag_keyword;
        });
    }
  }, []);
  return (
    <>
      <ServiceName />
      <ServiceDetails />
    </>
  );
}
