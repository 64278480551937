import { AppBar, Container, Toolbar, Grid } from "@mui/material";
import { Img } from "react-image";
import { CircleLoader } from "../Loaders";
import LOGO from "../../../Assets/IMG/logo.png";
import { NavLink } from "react-router-dom";

export default function DesktopNavigations() {
  return (
    <AppBar position="static" color="default">
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Grid container>
            <Grid item>
              <Img className="HeaderIMG" src={LOGO} loader={<CircleLoader />} />
            </Grid>
            <Grid
              item
              sx={{
                textAlign: "end",
                ml: "auto",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <NavLink className="navlink" to="/" title="Home Page">
                Home
              </NavLink>
              <NavLink className="navlink" to="/about" title="About Us">
                About
              </NavLink>
              <NavLink className="navlink" to="/services" title="Our Services">
                Services
              </NavLink>
              <NavLink className="navlink" to="/hireus" title="Hire Us">
                Hire Us
              </NavLink>
              <NavLink className="navlink" to="/career" title="Careers">
                Careers
              </NavLink>
              <NavLink className="navlink" to="/support" title="Support">
                Support
              </NavLink>
              <NavLink className="navlink" to="/contact" title="Contact Us">
                Contact
              </NavLink>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
