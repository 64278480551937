import { useEffect } from "react";
import { DataStore } from "../../../Store";
import { CareerLister, NoCareers } from "../Elements/CareersElements";
export default function CareersContent() {
  const metatags = DataStore((state) => state.metatags);
  const careers = DataStore((state) => state.careers);
  useEffect(() => {
    metatags
      .filter((tag) => tag.tag_page == "career")
      .forEach((row) => {
        document.title = "Minassa Solutions - " + row.tag_title;
        document.getElementsByTagName("meta")["description"].content =
          row.tag_description;
        document.getElementsByTagName("meta")["keywords"].content =
          row.tag_keyword;
      });
  }, []);
  return (
    <>{careers === null ? <NoCareers /> : <CareerLister data={careers} />}</>
  );
}
