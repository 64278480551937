import { Container } from "@mui/material";
import { Footer, Copyrights } from "../Elements/Footers";
export default function FooterContainer() {
  return (
    <Container maxWidth="auto" disableGutters>
      <Footer />
      <Copyrights />
    </Container>
  );
}
