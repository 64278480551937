import { Routes, Route } from "react-router-dom";
import { Container } from "@mui/material";
import {
  CareersContent,
  HireUsContent,
  ContactContent,
  SupportContent,
  ServicesContent,
  AboutContent,
  HomeContent,
  NotFoundContent,
  JobPreview,
  ServicePreview,
} from "../Pages";
export default function PageContainer() {
  return (
    <Container maxWidth="auto" disableGutters>
      <Routes>
        <Route path="/" element={<HomeContent />} />
        <Route path="/home" element={<HomeContent />} />
        <Route path="/about" element={<AboutContent />} />
        <Route path="/services" element={<ServicesContent />} />
        <Route path="/service/:sid" element={<ServicePreview />} />
        <Route path="/hireus" element={<HireUsContent />} />
        <Route path="/career" element={<CareersContent />} />
        <Route path="/job/:jid" element={<JobPreview />} />
        <Route path="/support" element={<SupportContent />} />
        <Route path="/contact" element={<ContactContent />} />
        <Route path="/*" element={<NotFoundContent />} />
      </Routes>
    </Container>
  );
}
