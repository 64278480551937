import { Container } from "@mui/material";
export default function Copyrights() {
  const SetYear = new Date().getFullYear();
  return (
    <Container
      maxWidth="auto"
      sx={{ p: 1, textAlign: "center", fontSize: "x-small" }}
    >
      © Copyright Minass Solutions {SetYear}, All rights reserved.
    </Container>
  );
}
