import { Container, Box, Typography } from "@mui/material";
import { Zoom } from "react-awesome-reveal";
import LocationOnIcon from "@mui/icons-material/LocationOn";
export default function ContactForm() {
  return (
    <Box className="grayBox">
      <Container maxWidth="lg" sx={{ px: 4, py: 2, mt: 1 }}>
        <Zoom delay={200} triggerOnce>
          <Box
            sx={{
              my: 2,
              display: "flex",
              alignItems: "center",
              width: "80%",
              padding: "0 9%",
            }}
          >
            <Box className="conticon">
              <LocationOnIcon fontSize="large" />
            </Box>
            <Box sx={{ p: 3 }}>
              <Typography>
                Iraq - Erbil - Zanko City - House Number C-127
              </Typography>
            </Box>
          </Box>
        </Zoom>
      </Container>
    </Box>
  );
}
