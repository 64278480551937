import { JobInfo, ApplicationForm } from "../Elements/JobApplicationElement";
import { useEffect } from "react";
import { DataStore } from "../../../Store";
import { useParams } from "react-router-dom";
export default function JobPreview() {
  const metatags = DataStore((state) => state.metatags);
  const { jid } = useParams();
  const toview = metatags.filter(
    (cont) => cont.tag_page == "job" && cont.tag_sub_id === jid
  );
  useEffect(() => {
    if (toview === null) {
      metatags
        .filter((tag) => tag.tag_page == "404")
        .forEach((row) => {
          document.title = "Minassa Solutions - " + row.tag_title;
          document.getElementsByTagName("meta")["description"].content =
            row.tag_description;
          document.getElementsByTagName("meta")["keywords"].content =
            row.tag_keyword;
        });
    } else {
      metatags
        .filter((tag) => tag.tag_page == "job" && tag.tag_sub_id == jid)
        .forEach((row) => {
          document.title = "Minassa Solutions - " + row.tag_title;
          document.getElementsByTagName("meta")["description"].content =
            row.tag_description;
          document.getElementsByTagName("meta")["keywords"].content =
            row.tag_keyword;
        });
    }
  }, []);
  return (
    <>
      <JobInfo />
      <ApplicationForm />
    </>
  );
}
