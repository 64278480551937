import { Container, Box, Typography } from "@mui/material";
import { Zoom, Fade } from "react-awesome-reveal";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import SupportIcon from "@mui/icons-material/Support";
import InfoIcon from "@mui/icons-material/Info";
import GroupsIcon from "@mui/icons-material/Groups";
import { Link } from "react-router-dom";
export default function ContactInfo() {
  return (
    <Container maxWidth="lg" sx={{ p: 4, mt: 5 }}>
      <Box>
        <Box>
          <Zoom cascade delay={100} duration={1000} triggerOnce>
            <Box
              sx={{
                my: 2,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box className="conticon">
                <GroupsIcon fontSize="large" />
              </Box>
              <Box sx={{ p: 3 }}>
                <Typography sx={{ fontWeight: "800" }}>Sales Team</Typography>
                <Link className="contlink" to="mailto:sales@minassa.tech">
                  sales@minassa.tech
                </Link>
              </Box>
            </Box>
            <Box
              sx={{
                my: 2,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box className="conticon">
                <SupportIcon fontSize="large" />
              </Box>
              <Box sx={{ p: 3 }}>
                <Typography sx={{ fontWeight: "800" }}>Support Team</Typography>
                <Link className="contlink" to="mailto:support@minassa.tech">
                  support@minassa.tech
                </Link>
              </Box>
            </Box>
            <Box
              sx={{
                my: 2,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box className="conticon">
                <InfoIcon fontSize="large" />
              </Box>
              <Box sx={{ p: 3 }}>
                <Typography sx={{ fontWeight: "800" }}>
                  Genral Information
                </Typography>
                <Link className="contlink" to="mailto:info@minassa.tech">
                  info@minassa.tech
                </Link>
              </Box>
            </Box>
            <Box
              sx={{
                my: 2,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box className="conticon">
                <LocalPhoneIcon fontSize="large" />
              </Box>
              <Box sx={{ p: 3 }}>
                <Typography sx={{ fontWeight: "800" }}>
                  964(0)751 822 5485
                </Typography>
                <Typography sx={{ fontWeight: "800" }}>
                  964(0)783 448 5686
                </Typography>
              </Box>
            </Box>
          </Zoom>
        </Box>
        <Box></Box>
        <Box sx={{ width: "5%" }}></Box>
      </Box>
    </Container>
  );
}
