import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Snackbar,
} from "@mui/material";
import { useState } from "react";
import { Zoom } from "react-awesome-reveal";
import { useParams } from "react-router-dom";
import { APIURL } from "../../../../Context/configs";
import axios from "axios";
import { FacebookLoader } from "../../../Common/Loaders";
export default function ApplicationForm() {
  const [RPTFILE, SetRPTFILE] = useState("");
  const [Uploading, SetUploading] = useState(false);
  const [startedUpload, SetstartedUpload] = useState(false);
  const [SnackBarType, SetSnackBarType] = useState("");
  const [SnackBarMessage, SetSnackBarMessage] = useState("");
  const [SnackBarShow, SetSnackBarShow] = useState(false);
  const { jid } = useParams();
  const startUpload = (event) => {
    SetUploading(false);
    SetstartedUpload(true);
    const formData = new FormData();
    formData.append("cv", event.target.files[0]);
    formData.append("jid", jid);
    axios
      .post(APIURL + "site/uploadcv.php", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(function (response) {
        response.data.forEach((item) => {
          if (item.id === 1) {
            SetRPTFILE(item.message);
            setTimeout(() => {
              SetstartedUpload(false);
              SetUploading(true);
            }, 3000);
          } else {
            setTimeout(() => {
              SetstartedUpload(false);
              SetUploading(true);
            }, 3000);
          }
        });
      });
  };
  const sendRequest = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    fetch(APIURL + "site/jobApplication.php", {
      method: "post",
      body: JSON.stringify({
        jid: jid,
        fn: data.get("fullname"),
        em: data.get("email"),
        cv: RPTFILE,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackBarMessage(item.content);
          SetSnackBarShow(true);
          if (item.id === 1) {
            SetSnackBarType("success");
          } else {
            SetSnackBarType("error");
          }
        });
        event.target.reset();
      });
  };
  return (
    <Container maxWidth="lg" sx={{ p: 4, mt: 1 }}>
      <Box component="form" onSubmit={sendRequest} sx={{ my: 3 }}>
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          className="TextPurple"
        >
          Apply Now
        </Typography>
        <Box sx={{ display: "none" }}>
          <TextField id="id" name="id" defaultValue={jid} />
        </Box>
        <Zoom cascade delay={100} duration={600} triggerOnce>
          <Box sx={{ mt: 2 }}>
            <TextField
              id="fullname"
              name="fullname"
              label="Your Name"
              variant="standard"
              color="secondary"
              fullWidth
              required
              size="small"
              InputLabelProps={{
                style: { color: "#6a1b9a" },
              }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              id="email"
              name="email"
              label="Your Email"
              variant="standard"
              color="secondary"
              fullWidth
              required
              type="email"
              size="small"
              InputLabelProps={{
                style: { color: "#6a1b9a" },
              }}
            />
          </Box>
          <Box sx={{ my: 2, pt: 5 }}>
            <Typography className="TextPurple">
              Upload Your Resume{" "}
              <Typography
                sx={{ color: "red", fontSize: "x-small" }}
                variant="body"
                className="small"
              >
                (Only PDF files accepted)
              </Typography>
            </Typography>
          </Box>
          <Box sx={{ my: 2 }}>
            <TextField
              id="file"
              name="file"
              variant="standard"
              color="secondary"
              fullWidth
              required
              type="file"
              size="small"
              onChange={startUpload}
              inputProps={{ accept: "application/pdf" }}
            />
          </Box>
        </Zoom>
        {startedUpload && (
          <Box sx={{ textAlign: "center", my: 2 }}>
            <FacebookLoader />
          </Box>
        )}
        {Uploading && (
          <Box sx={{ textAlign: "center", my: 2 }}>
            File uploaded successfully
          </Box>
        )}
        <Box sx={{ mt: 5 }}>
          <Button type="submit" fullWidth variant="contained" color="secondary">
            Apply For The Job
          </Button>
        </Box>
      </Box>
      <Snackbar
        autoHideDuration={3000}
        open={SnackBarShow}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert variant="filled" severity={SnackBarType} sx={{ width: "100%" }}>
          {SnackBarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
