import { SupportForm, SupportQA } from "../Elements/SupportElements";
import { useEffect } from "react";
import { DataStore } from "../../../Store";

export default function SupportContent() {
  const metatags = DataStore((state) => state.metatags);
  useEffect(() => {
    metatags
      .filter((tag) => tag.tag_page == "support")
      .forEach((row) => {
        document.title = "Minassa Solutions - " + row.tag_title;
        document.getElementsByTagName("meta")["description"].content =
          row.tag_description;
        document.getElementsByTagName("meta")["keywords"].content =
          row.tag_keyword;
      });
  }, []);
  return (
    <>
      <SupportForm />
      <SupportQA />
    </>
  );
}
