import { Container, Box, Typography } from "@mui/material";
import { Fade, Slide } from "react-awesome-reveal";
import { DataStore } from "../../../../Store";
import { STORAGEAPI } from "../../../../Context/configs";
import { Img } from "react-image";
import { DualRingLoader } from "../../../Common/Loaders";
export default function OurClients() {
  const ourclients = DataStore((state) => state.ourclients);
  return (
    <Container maxWidth="lg" sx={{ p: 4, mt: 5 }}>
      <Box sx={{ mb: 3 }}>
        <Slide direction="left" duration={200} triggerOnce>
          <Typography variant="h5">Our Clients</Typography>
        </Slide>
      </Box>
      <Box
        sx={{
          display: "flex",
          textAlign: "center",
          justifyContent: "space-between",
        }}
      >
        <Fade cascade direction="right" duration={400} triggerOnce>
          {ourclients.map((row) => (
            <Img
              src={STORAGEAPI + row.client_img}
              key={row.client_id}
              alt={row.client_name}
              title={row.client_name}
              className="clientIMG"
              loader={<DualRingLoader />}
            />
          ))}
        </Fade>
      </Box>
    </Container>
  );
}
