import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Snackbar,
} from "@mui/material";
import { Zoom } from "react-awesome-reveal";
import { useState } from "react";
import { APIURL } from "../../../../Context/configs";
export default function SupportForm() {
  const [SnackBarType, SetSnackBarType] = useState("");
  const [SnackBarMessage, SetSnackBarMessage] = useState("");
  const [SnackBarShow, SetSnackBarShow] = useState(false);
  const sendRequest = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    fetch(APIURL + "site/submitaTicket.php", {
      method: "post",
      body: JSON.stringify({
        sn: data.get("fullname"),
        em: data.get("email"),
        sub: data.get("subject"),
        mes: data.get("message"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackBarMessage(item.content);
          SetSnackBarShow(true);
          if (item.id === 1) {
            SetSnackBarType("success");
          } else {
            SetSnackBarType("error");
          }
        });
        event.target.reset();
      });
  };
  return (
    <Container maxWidth="lg" sx={{ p: 4, mt: 5 }}>
      <Box>
        <Typography variant="h5" className="TextPurple">
          Send a Support Ticket
        </Typography>
        <Box component="form" onSubmit={sendRequest} sx={{ my: 3 }}>
          <Zoom cascade delay={100} duration={600} triggerOnce>
            <Box>
              <TextField
                id="fullname"
                name="fullname"
                label="Your Name"
                variant="standard"
                color="secondary"
                fullWidth
                size="small"
                InputLabelProps={{
                  style: { color: "#6a1b9a" },
                }}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="email"
                name="email"
                label="Your Email"
                variant="standard"
                color="secondary"
                fullWidth
                type="email"
                size="small"
                InputLabelProps={{
                  style: { color: "#6a1b9a" },
                }}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="subject"
                name="subject"
                label="Subject"
                variant="standard"
                color="secondary"
                fullWidth
                size="small"
                type="subject"
                InputLabelProps={{
                  style: { color: "#6a1b9a" },
                }}
              />
            </Box>
            <Box sx={{ my: 2 }}>
              <TextField
                id="message"
                name="message"
                label="Your Message"
                variant="standard"
                color="secondary"
                fullWidth
                rows={4}
                multiline
                size="small"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
              >
                Send Messgae
              </Button>
            </Box>
          </Zoom>
        </Box>
      </Box>
      <Snackbar
        autoHideDuration={3000}
        open={SnackBarShow}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert variant="filled" severity={SnackBarType} sx={{ width: "100%" }}>
          {SnackBarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
