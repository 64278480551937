import { AddBoxOutlined } from "@mui/icons-material";
import { Container, Box, Typography } from "@mui/material";
import { Bounce } from "react-awesome-reveal";

export default function NotFound() {
  return (
    <Container maxWidth="lg" sx={{ p: 4, mt: 5, mb: 5 }}>
      <Box>
        <Typography variant="h4">Page Not Found</Typography>
      </Box>
      <Box>
        <Typography sx={{ py: 4 }}>
          Oops! The page you're looking for seems to have gone on a digital
          adventure. Don't worry, though.
        </Typography>
        <Typography>
          You can navigate back to safety using the menu above or contact us if
          you need further assistance.
        </Typography>
      </Box>
    </Container>
  );
}
