import { Container, Grid, Typography } from "@mui/material";
import { Img } from "react-image";
import { CircleLoader } from "../../../Common/Loaders";
import { Fade } from "react-awesome-reveal";
import { DataStore } from "../../../../Store";
import { STORAGEAPI } from "../../../../Context/configs";
import { useParams } from "react-router-dom";
import HTMLReactParser from "html-react-parser";
export default function JobHeader() {
  const pageheaders = DataStore((state) => state.pageheaders);
  const { jid } = useParams();
  const toview = pageheaders.filter(
    (cont) => cont.page_name == "job" && cont.page_sub_id === jid
  );
  return (
    <Container maxWidth="auto" className="pagerHeader" sx={{ p: 4 }}>
      <Container
        maxWidth="lg"
        sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}
      >
        {toview === null ? (
          <>
            {pageheaders
              .filter((cont) => cont.page_name == "404")
              .map((row) => (
                <Grid key={row.header_id} container>
                  <Grid item lg={6} md={6} sx={{ color: "white", py: 10 }}>
                    <Fade delay={200} triggerOnce>
                      <Typography variant="h4">{row.page_title}</Typography>
                    </Fade>
                    <Fade delay={400} triggerOnce>
                      <Typography sx={{ mt: 3 }}>
                        {HTMLReactParser(row.page_description)}
                      </Typography>
                    </Fade>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sx={{ textAlign: "end", my: "auto" }}
                  >
                    <Fade delay={600} triggerOnce>
                      <Img
                        src={STORAGEAPI + row.page_image}
                        loader={<CircleLoader />}
                      />
                    </Fade>
                  </Grid>
                </Grid>
              ))}
          </>
        ) : (
          <>
            {pageheaders
              .filter(
                (cont) => cont.page_name == "job" && cont.page_sub_id === jid
              )
              .map((row) => (
                <Grid key={row.header_id} container>
                  <Grid item lg={6} md={6} sx={{ color: "white", py: 10 }}>
                    <Fade delay={200} triggerOnce>
                      <Typography variant="h4">{row.page_title}</Typography>
                    </Fade>
                    <Fade delay={400} triggerOnce>
                      <Typography sx={{ mt: 3 }}>
                        {HTMLReactParser(row.page_description)}
                      </Typography>
                    </Fade>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sx={{ textAlign: "end", my: "auto" }}
                  >
                    <Fade delay={600} triggerOnce>
                      <Img
                        src={STORAGEAPI + row.page_image}
                        loader={<CircleLoader />}
                      />
                    </Fade>
                  </Grid>
                </Grid>
              ))}
          </>
        )}
      </Container>
    </Container>
  );
}
