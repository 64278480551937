import { MobileNavigations } from "../Components/Common/Navigations";
import {
  HeadersContainer,
  PageContainer,
  FooterContainer,
} from "../Components/MobileComponents/Containers";

export default function MobileView() {
  return (
    <>
      <MobileNavigations />
      <HeadersContainer />
      <PageContainer />
      <FooterContainer />
    </>
  );
}
