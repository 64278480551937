import { Container, Grid, Box, Typography } from "@mui/material";
import { Img } from "react-image";
import { CircleLoader } from "../../../Common/Loaders";
import BG from "../../../../Assets/IMG/homebanner1.png";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Slide, Zoom, Fade, JackInTheBox } from "react-awesome-reveal";
import { DataStore } from "../../../../Store";
export default function NewService() {
  const homepageservices = DataStore((state) => state.homepageservices);
  return (
    <Container maxWidth="auto" sx={{ p: 4, mt: 5 }} className="BlackBox">
      <Container maxWidth="lg">
        <Slide direction="left" duration={200} triggerOnce>
          <Typography variant="h5" sx={{ color: "white" }}>
            Our Services
          </Typography>
        </Slide>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            py: 8,
            color: "white",
          }}
        >
          <Fade duration={400} triggerOnce cascade>
            {homepageservices
              .filter((cont) => cont.service_sec === 1)
              .map((row) => (
                <Typography
                  key={row.service_id}
                  sx={{ py: 2, display: "flex" }}
                >
                  <DoneAllIcon sx={{ mx: 2 }} />
                  {row.service_title}
                </Typography>
              ))}
          </Fade>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            py: 8,
            color: "white",
          }}
        >
          <Zoom duration={400} triggerOnce cascade>
            {homepageservices
              .filter((cont) => cont.service_sec === 2)
              .map((row) => (
                <Typography
                  key={row.service_id}
                  sx={{ py: 2, display: "flex" }}
                >
                  <DoneOutlineIcon sx={{ mx: 2 }} />
                  {row.service_title}
                </Typography>
              ))}
          </Zoom>
        </Box>
      </Container>
    </Container>
  );
}
