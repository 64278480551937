import { Container, Box, Typography, Grid } from "@mui/material";
import { Fade, JackInTheBox } from "react-awesome-reveal";
import { Img } from "react-image";
import { CircleLoader } from "../../../Common/Loaders";
import { DataStore } from "../../../../Store";
import { STORAGEAPI } from "../../../../Context/configs";
import { useParams } from "react-router-dom";
export default function ServiceName() {
  const ourservices = DataStore((state) => state.ourservices);
  const { sid } = useParams();
  return (
    <Container maxWidth="lg" sx={{ p: 4, mt: 5 }}>
      {ourservices
        .filter((ser) => ser.service_min_url === sid)
        .map((row) => (
          <Box key={row.service_id} sx={{ my: 2 }}>
            <Grid container spacing={3}>
              <Grid item lg={3} md={4}>
                <JackInTheBox delay={150} triggerOnce>
                  <Img
                    className="serIMG"
                    src={STORAGEAPI + row.service_logo}
                    loader={<CircleLoader />}
                  />
                </JackInTheBox>
              </Grid>
              <Grid item lg={9} md={8}>
                <Fade cascade delay={200} duration={300} triggerOnce>
                  <Box sx={{ my: 2 }}>
                    <Typography className="TextPurple" variant="h5">
                      {row.service_name}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 8 }}>
                    <Typography variant="body">
                      {row.service_description}
                    </Typography>
                  </Box>
                </Fade>
              </Grid>
            </Grid>
          </Box>
        ))}
    </Container>
  );
}
