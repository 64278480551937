import { Container, Box, Typography, Paper } from "@mui/material";
import { Zoom, Fade } from "react-awesome-reveal";

export default function NoCareers() {
  return (
    <Container maxWidth="lg" sx={{ p: 4, mt: 5 }}>
      <Box>
        <Fade delay={100} triggerOnce>
          <Typography variant="h5" className="TextPurple" sx={{ px: 2 }}>
            Available Jobs
          </Typography>
        </Fade>
        <Box>
          <Paper elevation={3} sx={{ textAlign: "center", my: 4, p: 3 }}>
            <Typography variant="h6">No Jobs Are Available</Typography>
            <Typography sx={{ my: 5 }}>
              Please visit us later when some jobs are Available
            </Typography>
          </Paper>
        </Box>
      </Box>
    </Container>
  );
}
