import { create } from "zustand";
const DataStore = create((set) => ({
  metatags: [],
  careers: [],
  pageheaders: [],
  homepagefeatures: [],
  homepageservices: [],
  ourclients: [],
  ourmission: [],
  ourvision: [],
  ourteam: [],
  ourservices: [],
  contactdetails: [],
  footerser: [],
  setmetatags: (data) => set((state) => ({ metatags: data })),
  setcareers: (data) => set((state) => ({ careers: data })),
  setpageheaders: (data) => set((state) => ({ pageheaders: data })),
  sethomepagefeatures: (data) => set((state) => ({ homepagefeatures: data })),
  sethomepageservices: (data) => set((state) => ({ homepageservices: data })),
  setourclients: (data) => set((state) => ({ ourclients: data })),
  setourmission: (data) => set((state) => ({ ourmission: data })),
  setourvision: (data) => set((state) => ({ ourvision: data })),
  setourteam: (data) => set((state) => ({ ourteam: data })),
  setourservices: (data) => set((state) => ({ ourservices: data })),
  setcontactdetails: (data) => set((state) => ({ contactdetails: data })),
  setfooterser: (data) => set((state) => ({ footerser: data })),
}));

export default DataStore;
